<template>
<div class="c-preload-modal" @click.prevent="onClickOutsideModal" @touchend.prevent="onClickOutsideModal">
    <transition name="c-preload-modal">
        <div class="c-preload-modal-mask">
          <div class="c-preload-modal-wrapper">
          <div v-if="permissionsAcquired">
            <div :class="{'c-preload-modal-container':true, 'loading': isDownloading}" @click.prevent="onClickModalContainer" @touchend.prevent="onClickModalContainer">

              <div class="c-preload-modal-header">
                <slot name="header">
                  <!-- default header -->
                </slot>
              </div>
              <button v-if="!isDownloading" tabindex="0" :title="$t('global.closeModal')" class="close">
                <span class="xp xp--large xp-close"></span>
              </button>
              <div class="preload-wrapper">
                <p :class="{'hidden': isDownloading}">{{$t('global.downloadContent')}}</p>
                <!-- $t('global.rewind') -->
                <p :class="{'hidden': !isDownloading}">{{$t('global.downloadingContent')}}</p>
                <button v-if="!isDownloading" id="start-preload" @click.stop="onStartPreloadClick" @touchend.stop="onStartPreloadClick" class="c-preload__button" title="Preload starten" autofocus>
                  <span class="xp xp--huge xp-download"></span>
                </button>
                <button v-else id="stop-preload" @click.stop="onStopPreloadClick" @touchend.stop="onStopPreloadClick" class="c-preload__button" title="Cancel Preload" autofocus>
                  <span class="xp xp--huge xp-close"></span>
                </button>
                <div class="progress-bar-wrapper" v-if="isDownloading">
                    <xp-progress-bar v-bind:value='downloadProgress'></xp-progress-bar>
                </div>
                <!-- <div v-if="shouldSelectModule"> -->
                <ul v-if="shouldSelectModule" class="c-pages-menu menu">
                  <li><p>Modulauswahl</p></li>
                  <li class="c-pages-menu__item" v-for="submodule in preloadModulesArray" :key="submodule">
                    <p v-text="submodule"></p>
                    <input type="checkbox" :id="submodule" :name="submodule" :value="submodule" :class="{'disabled': isDownloading}">
                  </li>
                </ul>
              </div>
  </div> <!-- modal container -->
  </div> <!-- alert wrapper -->
            </div> <!-- modal-wrapper -->
  </div> <!-- modal-mask -->

  </transition>
</div>
</template>

<script>
import XpProgressBar from './XpProgressBar'

export default {
  name: 'XpPreloadModal',
  components: {
    XpProgressBar
  },
  props: {
    preloadPath: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      answerSectionID: null,
      isDownloading: false,
      downloadProgress: 0,
      selectedPreloadModule: '',
      preloadModulesArray: [],
      shouldSelectModule: false,
      startTime: null,
      nextPageId: 0,
      permissionsAcquired: false
    }
  },
  async mounted () {
    console.log('mounting xppreloadpage, custom gnm modal')
    // const preloadModules = this.$route.path.replace('/preload/preload', '')
    // const urlParts = preloadModules.split('/').filter(function (i) { return i })
    // console.log('urlparts ', urlParts)
    // preloadModules = urlParts[0]
    // this.nextPageId = urlParts[1].replace('PageID=', '')
    const preloadModules = this.preloadPath.replace('preload', '')
    console.log(preloadModules)
    this.preloadModulesArray = preloadModules.split('-').filter(function (i) { return i })
    console.log(this.preloadModulesArray)
    if (this.preloadModulesArray.length > 1) {
      this.shouldSelectModule = true
    } else {
      this.selectedPreloadModule = 'preload-' + this.preloadModulesArray[0]
    }
    window.setPreloadProgress = this.setPreloadProgress
    if (this.$xp.device.platform === 'android') {
      const storagePermissions = [
        window.cordova.plugins.diagnostic.permission.WRITE_EXTERNAL_STORAGE,
        window.cordova.plugins.diagnostic.permission.READ_EXTERNAL_STORAGE
      ]
      try {
        if (window.confirm(this.$t('global.requestStoragePermissions'))) {
          console.log('asking for permissions')
          await this.$xp.device.checkPermissions(storagePermissions)
        } else {
          console.log('going home')
          this.$xp.history.goHome()
        }
      // window.alert('Da Sie sich für eine Nutzung im Museum entschieden haben werden nun die Inhalte im Gerätespeicher abgelegt. Später erfolgt ein Zugriff darauf.\nIn keinem Fall wird dabei auf Ihre persönlichen Daten zugegriffen.')
      } catch (error) {
        console.log('error getting permissions, preload failed', error)
        this.$xp.history.goHome()
      }
    }
    console.log('should start preload')
    this.permissionsAcquired = true
    this.onStartPreloadClick()
    // TODO: Select Module and download selected
  },
  methods: {
    onStartPreloadClick (e) {
    //   e.stopPropagation()
      this.$xp.content.setContentPreloaded(false)
      this.startTime = new Date()
      if (this.selectedPreloadModule === '') {
        console.log('shouldselectmodule')
      }

      console.log(this.$xp.settings.baseUrl)

      //   const preloadUrl = this.$xp.settings.baseUrl + 'xpedeo/' + this.selectedPreloadModule + '.txt'
      const preloadUrl = this.$xp.settings.baseUrl + 'xpedeo/pages-to-sync.txt?generate'

      console.log(preloadUrl)
      if (window.XpedeoPreloader) {
        window.XpedeoPreloader.startPreloadWithUrl(
          preloadUrl ? [preloadUrl] : [],
          function () {
            // eslint-disable-next-line no-undef
            console.log('success calling startPreloadWithUrl')
          },
          (errMsg) => {
            // eslint-disable-next-line no-undef
            console.log('error calling startpreloadwithurl', errMsg)
          })
      }
      this.isDownloading = true
    },
    onStopPreloadClick (e) {
    //   e.stopPropagation()
      console.log('StopPreload Called')
      this.isDownloading = false
      if (window.XpedeoPreloader) {
        window.XpedeoPreloader.cancelPreload(
          function () {
            // eslint-disable-next-line no-undef
            console.log('success calling cancelPreload')
          },
          (errMsg) => {
            console.log('error calling cancelPreload', errMsg)
          })
      }
      this.$xp.history.goHome()
    },
    setPreloadProgress (progress) {
      console.log('setting progress', progress)
      this.downloadProgress = progress
      console.log(this.downloadProgress)
    },
    onCloseClick (e) {
      e.stopPropagation()
      console.log('onCloseClick')
      this.$xp.history.goHome()
    },
    onClickOutsideModal (e) {
      e.stopPropagation()
      console.log('onClickOutsideModal')
      if (!this.isDownloading) { this.$xp.content.showPreloadModal = false }
    },
    onClickModalContainer (e) {
      e.preventDefault()
      e.stopPropagation()
      e.stopImmediatePropagation()
      return false
    }
    // selectAnswer (sectionID) {
    //   this.answerSectionID = sectionID
    //   // TODO: save Answer to preload
    // }
  },
  watch: {
    downloadProgress: {
      handler: function (newValue, oldValue) {
        if (newValue === 100) {
          this.$xp.content.setContentPreloaded(true)
          const now = new Date()
          const elapsed = (now - this.startTime) / 1000
          console.log(`preload took ${elapsed} seconds`)
          this.isDownloading = false
          const nextPageId = window.localStorage.getItem('preloadNextPageId')
          this.$xp.content.showPreloadModal = false
          window.alert(this.$t('global.preloadSuccessfulMessage'))
          if (this.$xp.content.pageData.NextPageID) {
            this.$router.push('/page/' + this.$xp.content.pageData.NextPageID)
          } else {
            console.warn('Splash screen has no next page')
            this.$xp.history.goHome()
          }
        //   console.log(`Next page id ${nextPageId}`)
        //   if (nextPageId !== 0) {
        //     this.$router.push('/page/' + nextPageId)
        //   } else {
        //     this.$xp.history.goHome()
        //   }
        }
      }
    }
  }
}
</script>
